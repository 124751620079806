.refresh-btn {
  margin-left: 500px;
  margin-top: 50px;

  .button {
    outline: none;
    border: none;
    width: 240px;
    height: 50px;
    cursor: pointer;
    background-color: red;
    color: white;
    border-radius: 7px;
    font-size: 20px;
  }
}

.balance-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 340px;
  margin-top: 20px;
  width: 500px;
  height: 150px;
  background: rgb(240, 235, 235);
  border-radius: 7px;

  .data-left {
    margin-left: 20px;
  }
  .data-right {
    margin-right: 20px;
  }
}

.spinner {
  margin-left: 240px;
}
