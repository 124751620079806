.phonenumber {
	margin-top: 10px;
	padding: 5px;
	display: flex;
	flex-direction: row !important;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #ddd;
	border-radius: 4px;


	.number {
		font-size: 16px;
		font-weight: bold;
		margin: 0 10px;
		cursor: pointer;
	}
}