.card {
	margin-top: 10px;
	padding: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	img {
		width: 50px;
		margin-right: 10px;
	}

	.value {
		font-size: 18px;
		font-weight: bold;
		margin: 0 20px;
	}

	.code {
		font-size: 18px;
		font-weight: bold;
		margin-right: 10px;
		cursor: pointer;
	}

	.date {
		font-size: 14px;
		font-weight: bold;
		margin-right: 10px;
	}
}

.card-details {
	font-size: 16px;
	font-weight: bold;
	background: #f7f7f7;
	border-radius: 4px;
	padding: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
	
	.card-balance {
		text-transform: uppercase;

	}
}

.voucher-code {
	font-size: 30px;
	font-weight: bold;
	border: 1px dashed #ddd;
	background: #f7f7f7;
	text-align: center;
	border-radius: 4px;
	padding: 20px;
}
