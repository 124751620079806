.ej-nav {
  background-color: #fe4f38;
  padding: 0 !important;

  .navbar-nav {
    flex-grow: 1;
    justify-content: flex-end;
  }

  a {
    color: white !important;
    font-weight: bold;
    padding: 15px 20px;
  }
}

.custom {
  list-style: none;
  width: 100%;
  display: inline-flex;
  padding: 0;

  li {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 14px;
    color: black;
    cursor: pointer;
    font-weight: 400;
  }
}

a.is-active {
  background-color: #fff;
  border-bottom: 1px solid #fff;
  color: #fe4f38 !important;
}

.navbarlink {
  margin-right: 1px;
}

a.navbarlink {
  text-decoration: none !important;

  background-size: 200% 200%;
  background-image: linear-gradient(to top, #ffffff 50%, transparent 50%);
  -webkit-transition: background-position 300ms, color 300ms ease,
    border-color 300ms ease;
  -moz-transition: background-position 300ms, color 300ms ease,
    border-color 300ms ease;
  -ms-transition: background-position 300ms, color 300ms ease,
    border-color 300ms ease;
  -o-transition: background-position 300ms, color 300ms ease,
    border-color 300ms ease;
  transition: background-position 300ms, color 300ms ease,
    border-color 300ms ease;
}

a.navbarlink:hover {
  text-decoration: none !important;
  color: #fe4f38 !important;
  background-image: linear-gradient(to top, #ffffff 51%, transparent 50%);
  background-position: 0 100%;
  -webkit-transition: background-position 300ms, color 300ms ease,
    border-color 300ms ease;
  -moz-transition: background-position 300ms, color 300ms ease,
    border-color 300ms ease;
  -ms-transition: background-position 300ms, color 300ms ease,
    border-color 300ms ease;
  -o-transition: background-position 300ms, color 300ms ease,
    border-color 300ms ease;
  transition: background-position 300ms, color 300ms ease,
    border-color 300ms ease;
  cursor: pointer;
}
