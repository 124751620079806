.refresh-btn {
  margin-left: 500px;
  margin-top: 50px;

  .button {
    outline: none;
    border: none;
    width: 240px;
    height: 50px;
    cursor: pointer;
    background-color: red;
    color: white;
    border-radius: 7px;
    font-size: 20px;
  }
}

.balance-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 340px;
  margin-top: 20px;
  width: 500px;
  height: 150px;
  background: rgb(240, 235, 235);
  border-radius: 7px;

  .data-left {
    margin-left: 20px;
  }
  .data-right {
    margin-right: 20px;
  }
}

.spinner {
  margin-left: 240px;
}


.cardTile {
  background: #f9f9f9 !important;
  display: flex;
  flex-direction: column;

  img {
    width: 30% !important;
  }

  .card-text {
    width: 100%;
    text-align: center;

    .value {
      font-size: 32px;
      font-weight: bold;
      margin-top: 20px;
    }

    .name {
      font-size: 18px;
      text-transform: uppercase;
    }
  }

  .refreshBtn {
    background: none;
    border: 0;
    position: absolute;
    color: #d1d1d1;
    top: 0;
    right: 0;

    &:hover {
      background: none;
      color: black;
    }
  } 
}